import Head from 'next/head';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import AppBar from '../components/OneControl/AppBar';
import { configuration } from '../lib/configuration';
import { hasTokenExpired } from '../lib/utility';
import AppContext from '../store/app-context';
import Styles from '../lib/Styles';
import classes from '../pages/start/start.module.scss';
import withServerPropsCopiedToClient from '../HOC/withServerPropsCopiedToClient';

export { getServerSideEnvVars as getServerSideProps } from '../lib/server-props';

export interface IPageProps {
  authorized: boolean
  accessToken: string | null
  accessTokenExpirationDate: number | null
  email: string | null
  username: string | null
  process: any
  deployment: string
  contactid: string
  page: string | null
}

/**
 * This is the root page ('/').  It will redirect the user to either the '/start' page or the '/getconnected' page based on if the accessToken is found and still valid. 
 * @param props
 * @returns 
 */
function HomePage(props: IPageProps) {
  const { authorized, accessTokenExpirationDate, process } = props;
  const { authenticated, setAuthenticated, token, setToken } = useContext(AppContext);
  const cx = new Styles(classes).cx;
  const router = useRouter();

  configuration.setProcessIfNotAlreadySet(process);

  useEffect(() => {
    const code = localStorage.getItem('kcode');
    if (code && (authorized === true) && (!hasTokenExpired(accessTokenExpirationDate))) {
      localStorage.setItem('accessTokenExpiration', '' + accessTokenExpirationDate);
      router.replace('/getconnected');
    } else {
      localStorage.removeItem('jsforce0_access_token');
      localStorage.removeItem('lastAppInsightsPost');
      setAuthenticated(false);
      setToken('');
      router.replace('/start');
    }
  }, [authenticated, token, accessTokenExpirationDate, authorized, setAuthenticated, setToken, router])

  return (
    <div>
      <Head>
        <title>ConnectAnywhere</title>
        <link rel="icon" href='/favicon.ico' />
      </Head>
      <AppBar />
      <div className={cx('container')}>
        <div className={cx('overlay')} />
        <div className={cx('contents')} />
      </div>
    </div>
  )
}

export default withServerPropsCopiedToClient(HomePage);